import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';

// Components
import ContentContainer from '../components/global/contentContainer';

import TimetableView from '../components/events/timetableView';
import FilterView from '../components/events/filterView';
import DisplayView from '../components/events/displayView';

import Subtitle from '../components/text/subtitle';
import moment from 'moment';

const Theme = {
  highlightColor: '#3c82e6',
  textColor: '#ffffff',
};

const ContainerTheme = {
  backgroundColor: '#ebfaff',
};

let timeout = false;
let delay = 250;
let filteredEventsList;
let formattedEvents = null;

class OnlineEventsTimetable extends PureComponent {
  constructor(props) {
    super(props);

    let currentTime = moment();

    this.state = {
      activeView: 'List',
      filterView: false,
      eventsList: null,
      filters: [],
      filteredEvents: null,
      // checked: true,
      checkedItems: new Map(null, false),
      width: 599,
      loading: true,
      currentTime: currentTime,

      allTuition: false,
      allTutors: false,
      allLocations: false,
      allClassTypes: false,
      allSubjects: false,

      freshEvents: null,
    };

    this.toggleActiveView = this.toggleActiveView.bind(this);
    this.toggleFilterView = this.toggleFilterView.bind(this);
    this.toggleAllFiltersOfType = this.toggleAllFiltersOfType.bind(this);
    this.getWindowDimensions = this.getWindowDimensions.bind(this);

    this.triggerRemoveFilters = this.triggerRemoveFilters.bind(this);
    this.triggerAddFilters = this.triggerAddFilters.bind(this);
  }

  componentDidMount(props) {
    this.setState(
      {
        freshEvents: this.props.data.allPrismicEvent.edges,
      },
      () => {
        if (
          this.state &&
          this.state.freshEvents &&
          this.state.freshEvents !== null &&
          this.state.freshEvents.length >= 1
        ) {
          formattedEvents = this.state.freshEvents
            .filter(product => {
              let eventDateTime = moment(
                product.node.data.rawDate + ' ' + product.node.data.start_time
              );

              let pastEventCheck = moment(this.state.currentTime).isSameOrAfter(
                eventDateTime
              );

              return pastEventCheck === false;
            })
            .map(event => {
              return (event = {
                ...event,
                ...event.node.data,
                activePrice: `full_price`,
                quantity: 1,
                sku: event.node.prismicId,
                productType: `event`,
              });
            });

          this.setState({
            eventsList: formattedEvents,
            filteredEvents: formattedEvents,
            loading: false,
          });
        }
      }
    );

    let currentWindowWidth = window.innerWidth;
    window.addEventListener('load', this.getWindowDimensions);
    window.addEventListener('resize', () => {
      if (window.innerWidth !== currentWindowWidth) {
        // clear the timeout
        clearTimeout(timeout);
        // start timing for event "completion"
        timeout = setTimeout(this.getWindowDimensions, delay);
      }
    });
  }

  getWindowDimensions() {
    this.setState(
      {
        width: window.innerWidth,
      },
      () => {
        if (this.state.width < 600) {
          this.setState({
            activeView: 'List',
          });
        }
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.getWindowDimensions);
    window.removeEventListener('resize', this.getWindowDimensions);

    this.setState({
      freshEvents: null,
    });
  }

  triggerRemoveFilters(incomingFilters) {
    let filters = incomingFilters;

    filteredEventsList = this.state.eventsList.filter(function(item) {
      if (
        filters.includes(item.node.data.tutor.document.data.name.text) ||
        filters.includes(item.node.data.location.document.data.title.text) ||
        item.node.data.tuition_categories
          .filter(checkTuition => checkTuition.tuition !== null)
          .filter(checkTuition => checkTuition.tuition.document !== null)
          .map(tuition => tuition.tuition.document.data.title.text)
          .every(e => filters.includes(e)) ||
        item.node.data.subject_categories
          .filter(checkSubjects => checkSubjects.subject !== null)
          .filter(checkSubjects => checkSubjects.subject.document !== null)
          .map(
            subjectCategory => subjectCategory.subject.document.data.title.text
          )
          .every(e => filters.includes(e)) ||
        item.node.data.class_categories
          .filter(checkClasses => checkClasses.class_type !== null)
          .filter(checkClasses => checkClasses.class_type.document !== null)
          .map(
            classCategory => classCategory.class_type.document.data.title.text
          )
          .every(e => filters.includes(e))
      ) {
        return null;
      } else {
        return item;
      }
    });
    // This is where we update the filtered events
    this.setState({
      filteredEvents: filteredEventsList,
    });
  }

  triggerAddFilters(incomingFilters) {
    let filters = incomingFilters;

    filteredEventsList = this.state.eventsList
      .filter(item => {
        // Filter by tutor if applicable
        if (!filters.includes(item.node.data.tutor.document.data.name.text)) {
          return item;
        }
      })
      .filter(item => {
        // Filter by location if applicable
        if (
          !filters.includes(item.node.data.location.document.data.title.text)
        ) {
          return item;
        }
      })
      .filter(item => {
        // Filter by tuition categories if applicable
        if (item.node.data.tuition_categories.length >= 1) {
          if (
            !item.node.data.tuition_categories
              .filter(checkTuition => checkTuition.tuition !== null)
              .filter(checkTuition => checkTuition.tuition.document !== null)
              .map(tuition => tuition.tuition.document.data.title.text)
              .every(e => filters.includes(e))
          ) {
            return item;
          }
        } else {
          return item;
        }
      })
      .filter(item => {
        // Filter by tuition subject categories if applicable
        if (item.node.data.subject_categories.length >= 1) {
          if (
            !item.node.data.subject_categories
              .filter(checkSubjects => checkSubjects.subject !== null)
              .filter(checkSubjects => checkSubjects.subject.document !== null)
              .map(
                subjectCategory =>
                  subjectCategory.subject.document.data.title.text
              )
              .every(e => filters.includes(e))
          ) {
            return item;
          }
        } else {
          return item;
        }
      })
      .filter(item => {
        // Filter by tuition class categories if applicable
        // Filter by tuition subject categories if applicable
        if (item.node.data.class_categories.length >= 1) {
          if (
            !item.node.data.class_categories
              .filter(checkClasses => checkClasses.class_type !== null)
              .filter(checkClasses => checkClasses.class_type.document !== null)
              .map(
                classCategory =>
                  classCategory.class_type.document.data.title.text
              )
              .every(e => filters.includes(e))
          ) {
            return item;
          }
        } else {
          return item;
        }
      });

    // This is where we update the filtered events
    this.setState({
      filteredEvents: filteredEventsList,
    });
  }

  toggleActiveView(selectedView) {
    this.setState({
      activeView: selectedView,
    });
  }

  toggleFilterView() {
    this.setState(prevState => ({
      filterView: !prevState.filterView,
    }));
  }

  toggleAllFiltersOfType = (filters, target) => {
    let clickedFilter = target.name;
    let isChecked = target.checked;

    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(clickedFilter, isChecked),
    }));

    if (clickedFilter === 'allTuition') {
      if (this.state.allTuition === true) {
        let checkFilters = this.state.filters.filter(
          el => !filters.includes(el)
        );

        filters.forEach(item => {
          this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, false),
          }));
        });

        this.setState(
          prevState => ({
            allTuition: false,
            filters: checkFilters,
          }),
          () => {
            this.triggerRemoveFilters(this.state.filters);
          }
        );
      } else {
        this.setState(
          prevState => ({
            filters: [...filters, ...prevState.filters],
            allTuition: true,
          }),
          () => {
            filters.forEach(item => {
              this.setState(prevState => ({
                checkedItems: prevState.checkedItems.set(item, true),
              }));
            });
            this.triggerAddFilters(this.state.filters);
          }
        );
      }
    } else if (clickedFilter === 'allTutors') {
      if (this.state.allTutors === true) {
        let checkFilters = this.state.filters.filter(
          el => !filters.includes(el)
        );

        filters.forEach(item => {
          this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, false),
          }));
        });

        this.setState(
          prevState => ({
            allTutors: false,
            filters: checkFilters,
          }),
          () => {
            this.triggerRemoveFilters(this.state.filters);
          }
        );
      } else {
        this.setState(
          prevState => ({
            filters: [...filters, ...prevState.filters],
            allTutors: true,
          }),
          () => {
            filters.forEach(item => {
              this.setState(prevState => ({
                checkedItems: prevState.checkedItems.set(item, true),
              }));
            });

            this.triggerAddFilters(this.state.filters);
          }
        );
      }
    } else if (clickedFilter === 'allLocations') {
      if (this.state.allLocations === true) {
        let checkFilters = this.state.filters.filter(
          el => !filters.includes(el)
        );

        filters.forEach(item => {
          this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, false),
          }));
        });

        this.setState(
          prevState => ({
            allLocations: false,
            filters: checkFilters,
          }),
          () => {
            this.triggerRemoveFilters(this.state.filters);
          }
        );
      } else {
        this.setState(
          prevState => ({
            filters: [...filters, ...prevState.filters],
            allLocations: true,
          }),
          () => {
            filters.forEach(item => {
              this.setState(prevState => ({
                checkedItems: prevState.checkedItems.set(item, true),
              }));
            });

            this.triggerAddFilters(this.state.filters);
          }
        );
      }
    } else if (clickedFilter === 'allClassTypes') {
      if (this.state.allClassTypes === true) {
        let checkFilters = this.state.filters.filter(
          el => !filters.includes(el)
        );

        filters.forEach(item => {
          this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, false),
          }));
        });

        this.setState(
          prevState => ({
            allClassTypes: false,
            filters: checkFilters,
          }),
          () => {
            this.triggerRemoveFilters(this.state.filters);
          }
        );
      } else {
        this.setState(
          prevState => ({
            filters: [...filters, ...prevState.filters],
            allClassTypes: true,
          }),
          () => {
            filters.forEach(item => {
              this.setState(prevState => ({
                checkedItems: prevState.checkedItems.set(item, true),
              }));
            });

            this.triggerAddFilters(this.state.filters);
          }
        );
      }
    } else if (clickedFilter === 'allSubjects') {
      if (this.state.allSubjects === true) {
        let checkFilters = this.state.filters.filter(
          el => !filters.includes(el)
        );

        filters.forEach(item => {
          this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, false),
          }));
        });

        this.setState(
          prevState => ({
            allSubjects: false,
            filters: checkFilters,
          }),
          () => {
            this.triggerRemoveFilters(this.state.filters);
          }
        );
      } else {
        this.setState(
          prevState => ({
            filters: [...filters, ...prevState.filters],
            allSubjects: true,
          }),
          () => {
            filters.forEach(item => {
              this.setState(prevState => ({
                checkedItems: prevState.checkedItems.set(item, true),
              }));
            });

            this.triggerAddFilters(this.state.filters);
          }
        );
      }
    }
  };

  toggleFilterValue = target => {
    let filterTarget = target.name;
    const item = target.name;
    const isChecked = target.checked;

    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));

    if (this.state.filters.includes(filterTarget)) {
      // Filter is already in the array
      let removeFilter = this.state.filters.filter(
        filter => filter !== filterTarget
      );

      this.setState(
        {
          filters: removeFilter,
        },
        () => {
          if (
            this.state.allTuition === true ||
            this.state.allTutors === true ||
            this.state.allLocations === true ||
            this.state.allClassTypes === true ||
            this.state.allSubjects === true
          ) {
            this.triggerAddFilters(this.state.filters);
          } else {
            this.triggerRemoveFilters(this.state.filters);
          }
        }
      );
    } else {
      // Filter is not already in the array
      this.setState(
        prevState => ({
          filters: [...prevState.filters, filterTarget],
        }),
        () => {
          this.triggerAddFilters(this.state.filters);
        }
      );
    }
  };

  render(props) {
    const activeView = this.state.activeView;
    const filterToggle = this.state.filterView;

    return (
      <>
        <Helmet title={`Online Events`} />
        <Row css={ContainerTheme} justifyContent="center">
          <Col col={12}>
            <ContentContainer padding={'54px 36px'} mobilePadding={'20px 9px'}>
              <Row justifyContent="center">
                <TimetableView
                  onClick={this.toggleActiveView}
                  activeButton={activeView}
                />
                <FilterView
                  onClick={this.toggleFilterView}
                  toggleActive={filterToggle}
                  toggleFilterValue={this.toggleFilterValue}
                  checkedItems={this.state.checkedItems}
                  toggleAllFiltersOfType={this.toggleAllFiltersOfType}
                  eventsList={this.state.eventsList}
                />
              </Row>
            </ContentContainer>
          </Col>

          <Col col={12}>
            <ContentContainer
              padding={'0 36px'}
              mobilePadding={'0 9px 40px 9px'}
            >
              <Row>
                {this.state.loading === false ? (
                  <DisplayView
                    activeView={this.state.activeView}
                    filterToggle={this.state.filterView}
                    filteredEvents={this.state.filteredEvents}
                    theme={Theme}
                  />
                ) : (
                  <Col col={12}>
                    <Subtitle text={'Loading'} />
                  </Col>
                )}
              </Row>
            </ContentContainer>
          </Col>
        </Row>
      </>
    );
  }
}

export const query = graphql`
  query OnlineEventsTimetableQuery {
    prismicTimetable {
      data {
        title {
          text
        }
        text {
          text
        }
      }
    }
    allPrismicEvent(
      filter: {
        data: {
          date: { gte: "2023-07-24" }
          class_categories: {
            elemMatch: { class_type: { id: { eq: "XqgHKxIAACEAsfgW" } } }
          }
        }
      }
      sort: { order: ASC, fields: [data___date, data___start_time] }
    ) {
      edges {
        node {
          prismicId
          uid
          data {
            title {
              text
            }
            rawDate: date
            date(formatString: "ddd, DD MMM ‘YY")
            rawEndDate: end_date
            end_date(formatString: "ddd, DD MMM ‘YY")
            partner_event
            drawing_retreat
            patreon_status
            eventbrite_link {
              url
            }
            tutor {
              url
              document {
                ... on PrismicTutors {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            tuition_categories {
              tuition {
                document {
                  ... on PrismicTuition {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            class_categories {
              class_type {
                document {
                  ... on PrismicClassType {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            subject_categories {
              subject {
                document {
                  ... on PrismicSubject {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            start_time
            finish_time
            location {
              url
              document {
                ... on PrismicLocation {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            image {
              alt
              fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
                srcWebp
                srcSetWebp
              }
            }
            full_price
            discounted_price
            initial_number_of_places
          }
        }
      }
    }
  }
`;

export default OnlineEventsTimetable;
